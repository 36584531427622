import React from "react";
import { FeaturedNews } from "./FeaturedNews";
import { LatestNews } from "./LatestNews";

export const NewsFeed = ({
	featuredTitle,
	featuredNews,
	latestNewsTitle,
	latestNews,
}) => {
	return (
		<section className="grid lg:grid-cols-6 gap-20 w-9/12 mx-auto m-auto my-32">
			<FeaturedNews featuredTitle={featuredTitle} featuredNews={featuredNews} />
			<LatestNews latestNewsTitle={latestNewsTitle} latestNews={latestNews} />
		</section>
	);
};
