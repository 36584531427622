import React from "react";
import { motion } from "framer-motion";
import { FigureImage } from "../../../Atomics/FigureImage";

export const LatestNews = ({ latestNewsTitle, latestNews }) => {
	return (
		<>
			{latestNews && latestNews.length >= 1 && (
				<div className="row-start-1 lg:row-auto col-span-4 lg:col-span-2 flex flex-col">
					{latestNewsTitle && (
						<motion.h3
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1, type: "spring", stiffness: 100 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { y: -30, opacity: 0 },
							}}
							className="mb-4 text-lg font-bold text-[#213053]"
						>
							{latestNewsTitle}
						</motion.h3>
					)}
					{latestNews && (
						<ul className="flex flex-col space-y-10">
							{latestNews.map((news) => (
								<LatestNewsItem key={news.id} news={news} />
							))}
						</ul>
					)}
				</div>
			)}
		</>
	);
};

const LatestNewsItem = ({ news }) => {
	return (
		<li>
			<a
				href={news.link}
				target="_blank"
				rel="noopener noreferrer"
				className="flex flex-col space-y-4 group"
			>
				{news.title && (
					<h4 className="text-2xl md:text-3xl rtl:leading-normal font-semibold text-[#213053] group-hover:underline">
						{news.title}
					</h4>
				)}
				{news.text && (
					<p className=" font-normal text-[#213053]">{news.text}</p>
				)}
				<div className="flex items-center">
					{news.publisherLogo && (
						<FigureImage
							src={news.publisherLogo.sourceUrl}
							className="w-12 h-9 rounded-xl  flex items-center justify-center  mr-4 rtl:ml-4 rtl:mr-0"
						/>
					)}
					{news.publisherName && (
						<span className=" text-base font-semibold text-black">
							{news.publisherName}
						</span>
					)}
				</div>
				<div className="bg-black opacity-10 h-px" />
			</a>
		</li>
	);
};
