import React from "react";
import { motion } from "framer-motion";
import { textFadingVariant } from "../../../constants/animations";
import classNames from "classnames";

export const ContactBox = ({
	miniHeader,
	header,
	text,
	buttonLink,
	buttonText,
	className,
}) => {
	return (
		<motion.div
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true }}
			variants={{
				visible: { y: 0 },
				hidden: { y: 100 },
			}}
			className={classNames(
				"bg-gradient-to-b from-[#FFE600] to-[#FFC10E] w-10/12 lg:w-7/12  xl:w-7/12 mx-auto mb-32 rounded-[32px] flex flex-col py-10 px-[52px] items-start ",
				className
			)}
		>
			{miniHeader && (
				<motion.span
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					transition={{ duration: 1.6 }}
					variants={textFadingVariant}
					className=" font-semibold text-black mb-4"
				>
					{miniHeader}
				</motion.span>
			)}
			{header && (
				<motion.h3
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					variants={textFadingVariant}
					className="text-3xl font-semibold text-[#031432] mb-6"
				>
					{header}
				</motion.h3>
			)}
			<motion.p
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				variants={textFadingVariant}
				className="text-base font-normal text-[#464646] mb-4 2xl:w-9/12"
			>
				{text}
			</motion.p>
			{buttonLink && (
				<a href={buttonLink} target="_blank" rel="noopener noreferrer">
					<button className=" bg-white px-6 pt-[21px] pb-[18px] rounded-[20px] text-[#F6B904] font-semibold">
						{buttonText}
					</button>
				</a>
			)}
		</motion.div>
	);
};
