import React from "react";
import { motion } from "framer-motion";
import { FigureImage } from "../../../Atomics/FigureImage";

export const FeaturedNews = ({ featuredTitle, featuredNews }) => {
	return (
		<>
			{featuredNews && featuredNews.length >= 1 && (
				<div className="col-span-4 flex flex-col">
					{featuredTitle && (
						<motion.h3
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1, type: "spring", stiffness: 100 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { y: -30, opacity: 0 },
							}}
							className="mb-4 text-lg font-bold text-[#213053]"
						>
							{featuredTitle}
						</motion.h3>
					)}
					<ul className="flex flex-col space-y-10">
						{featuredNews &&
							featuredNews.map((news) => (
								<FeaturedNewsItem key={news.id} news={news} />
							))}
					</ul>
				</div>
			)}
		</>
	);
};

const FeaturedNewsItem = ({ news }) => {
	return (
		<li id={news.id} className="flex flex-col space-y-4">
			<a
				href={news.link}
				target="_blank"
				rel="noopener noreferrer"
				className="flex flex-col space-y-4 group"
			>
				{news.image && (
					<FigureImage
						className="aspect-[856/481] rounded-3xl"
						gatsbyImage={news.image}
						alt={news.title}
					/>
				)}
				{news.title && (
					<h4 className="text-2xl md:text-3xl lg:text-5xl font-semibold text-dark1 lg:leading-tight group-hover:underline">
						{news.title}
					</h4>
				)}
				{news.text && (
					<p className=" font-normal text-[#213053]">{news.text}</p>
				)}
				<div className="flex items-center">
					{news.publisherLogo && (
						<FigureImage
							src={news.publisherLogo.sourceUrl}
							className="w-12 h-9 rounded-xl  flex items-center justify-center mr-4 rtl:ml-4 rtl:mr-0"
						/>
					)}

					{news.publisherName && (
						<span className=" text-base font-semibold text-black">
							{news.publisherName}
						</span>
					)}
				</div>
			</a>
		</li>
	);
};
