import React from "react";
import Layouts from "../components/Layouts";
import Seo from "../components/Seo";
import { PageHeader } from "../components/Atomics/PageHeader";
import { Cta } from "../components/Cta";
import { NewsFeed } from "../components/Screens/News/NewsFeed";
import { ContactBox } from "../components/Screens/News/ContactBox";
import { graphql } from "gatsby";

const NewsPage = ({ data }) => {
	const latestNews = data.page.news.latestNews.news || [];
	const featuredNews = data.page.news.featuredNews.news || [];
	return (
		<>
			<Seo title="News" />
			<Layouts lang={"en"}>
				<PageHeader miniHeader="News" header="Kashat in the News" />
				<NewsFeed
					featuredTitle="FEATURED"
					featuredNews={featuredNews}
					latestNews={latestNews}
					latestNewsTitle="LATEST NEWS"
				/>
				<ContactBox
					miniHeader={data.page.mediaKitNews.mediaKitBanner.miniHeader}
					header={data.page.mediaKitNews.mediaKitBanner.header}
					text={data.page.mediaKitNews.mediaKitBanner.text}
					buttonText={data.page.mediaKitNews.mediaKitBanner.buttonText}
					buttonLink={data.page.mediaKitNews.mediaKitBanner.buttonLink}
				/>
				<Cta
					miniHeader="EGYPT's First Nano Financial Service Provider"
					header="Powering Access to Digital Financial Possibilities"
					subHeader="Kashat serves as the entry point to financial inclusion for the un- and underbanked population in Egypt by offering instant, small, short-term, productive loans."
				/>{" "}
			</Layouts>
		</>
	);
};
export default NewsPage;
export const pageQu = graphql`
	query EnglishNews {
		page: wpPage(databaseId: { eq: 294 }) {
			title
			news {
				featuredNews {
					news {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1920, placeholder: BLURRED)
								}
							}
						}
						title
						text
						publisherLogo {
							sourceUrl
						}
						publisherName
						link
					}
				}
				latestNews {
					news {
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 1920, placeholder: BLURRED)
								}
							}
						}
						title
						text
						publisherLogo {
							sourceUrl
						}
						publisherName
						link
					}
				}
			}
			mediaKitNews {
				mediaKitBanner {
					miniHeader
					header
					text
					buttonText
					buttonLink
				}
			}
		}
	}
`;
